/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_CATE_NEWS, GET_HOT_NEWS } from './slice';

export function* getHotNews({ payload }: any) {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      `/api/news/listing?SiteCode=${payload?.park}&hotNews=1`,
      undefined,
    );

    yield put(GET_HOT_NEWS.success(data));
  } catch (err) {
    yield put(GET_HOT_NEWS.failure());
  }
}
export function* getCateNews({ payload }: any) {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      `/api/news/categories?SiteCode=${payload.park}`,
      undefined,
    );

    yield put(GET_CATE_NEWS.success(data));
  } catch (err) {
    yield put(GET_CATE_NEWS.failure());
  }
}

const newsSagas: any = [
  takeLatest(GET_HOT_NEWS.TRIGGER, getHotNews),
  takeLatest(GET_CATE_NEWS.TRIGGER, getCateNews),
];

export default newsSagas;

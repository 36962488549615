export const MENU_HEADER = [
  {
    name: 'Ăn-chơi-mua',
    link: '',
    icon: 'far fa-flower',
    subMenu: true,
    children: [
      { link: 'an-uong', name: 'Ăn uống' },
      { link: 'vui-choi', name: 'Vui chơi' },
      { link: 'mua-sam', name: 'Mua sắm' },
    ],
  },
  {
    name: 'Lưu trú',
    link: 'luu-tru',
    icon: 'fa-solid fa-fork-knife',
  },
  {
    name: 'Điểm đến',
    link: 'diem-den',
    icon: 'fa-regular fa-cable-car',
    subMenu: true,
    children: [],
  },
  {
    name: 'Tin tức',
    link: 'tin-tuc',
    icon: 'fa-regular fa-newspaper',
  },
  // {
  //   name: 'Hoạt động',
  //   link: 'hoat-dong',
  //   icon: 'fa-regular fa-dharmachakra',
  // },
];

export const MENU_HEADER_MOBI = [
  {
    name: 'Ăn',
    link: 'an-uong',
    icon: 'far fa-flower',
    subMenu: true,
  },
  {
    name: 'Chơi',
    link: 'vui-choi',
    icon: 'far fa-flower',
    subMenu: true,
  },
  {
    name: 'Mua',
    link: 'mua-sam',
    icon: 'far fa-flower',
    subMenu: true,
  },
  {
    name: 'Lưu trú',
    link: 'luu-tru',
    icon: 'fa-solid fa-fork-knife',
  },
  {
    name: 'Điểm đến',
    link: 'diem-den',
    icon: 'fa-regular fa-cable-car',
  },
  {
    name: 'Tin tức',
    link: 'tin-tuc',
    icon: 'fa-regular fa-newspaper',
  },
  // {
  //   name: 'Hoạt động',
  //   link: 'hoat-dong',
  //   icon: 'fa-regular fa-dharmachakra',
  // },
];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_Cate } from './typesIndex';
import { I_News } from '@containers/Home/typesIndex';

export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  hotNews: null,
  cate: null,
};

export const GET_HOT_NEWS = createRoutine('News/getHotNews');
export const GET_CATE_NEWS = createRoutine('News/getCateNews');

const NewsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
  extraReducers: {
    [HYDRATE]: (state: ContainerState, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.news,
    }),

    [GET_HOT_NEWS.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_HOT_NEWS.SUCCESS]: (state, action: PayloadAction<I_News>) => {
      state.hotNews = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_HOT_NEWS.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    [GET_CATE_NEWS.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_CATE_NEWS.SUCCESS]: (state, action: PayloadAction<I_Cate[]>) => {
      state.cate = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_CATE_NEWS.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = NewsSlice;

export default reducer;

import LinkInOutApp from '@components/LinkInOutApp';
import React, { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_FOOTER } from '@containers/Home/slice';
import { selectFooter, selectListSpl } from '@containers/Home/selectors';
import Slider from 'react-slick';
import { useRouter } from 'next/router';

const Footer = memo(() => {
  const footerImageRef = useRef(null);
  const dispatch = useDispatch();
  const router = useRouter();

  const footer = useSelector(selectFooter);
  const listSpl = useSelector(selectListSpl);

  const footerImageOptions = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 0,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    arrows: false,
    slidesToShow: 1,
    adaptiveHeight: true,
    // cssEase: 'ease-in-out',
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      handleSlideTransition(oldIndex, newIndex);
    },
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleIntro = () => {
    if (router.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      router.push('/');
    }
  };

  const handleSlideTransition = (oldIndex, newIndex) => {
    const prevSlideElement =
      footerImageRef.current?.innerSlider.list.querySelector(
        `[data-index="${
          oldIndex - 1 < 0 ? listSpl?.data?.length - 1 : oldIndex - 1
        }"]`,
      );

    const currentSlideElement =
      footerImageRef.current?.innerSlider.list.querySelector(
        `[data-index="${oldIndex}"]`,
      );

    const nextSlideElement =
      footerImageRef.current?.innerSlider.list.querySelector(
        `[data-index="${newIndex}"]`,
      );

    setTimeout(() => {
      currentSlideElement?.classList.remove('left-to-right');
      currentSlideElement?.classList.remove('right-to-left');
      nextSlideElement?.classList.remove('left-to-right');
      nextSlideElement?.classList.remove('right-to-left');
      nextSlideElement?.classList.remove('current');
      prevSlideElement?.classList.remove('current');
      currentSlideElement?.classList.add('current');
      if (Math.abs(newIndex - oldIndex) === 1) {
        nextSlideElement?.classList.add(
          newIndex - oldIndex > 0 ? 'right-to-left' : 'left-to-right',
        );
      } else
        nextSlideElement?.classList.add(
          newIndex - oldIndex > 0 ? 'left-to-right' : 'right-to-left',
        );
    }, 10);
  };

  useEffect(() => {
    dispatch(GET_FOOTER());
  }, [dispatch]);

  return (
    <footer>
      <div className="footerTop">
        <div className="slider">
          <div className="--listImg">
            <Slider ref={footerImageRef} {...footerImageOptions}>
              {listSpl?.data?.map((item, i) => {
                return (
                  item?.imageResize && (
                    <LinkInOutApp
                      href={`/${item?.sitecode}`}
                      className="--img"
                      key={i}
                    >
                      <img
                        src={item?.imageResize?.thumb1920}
                        alt=""
                        className="--banner"
                      />
                    </LinkInOutApp>
                  )
                );
              })}
            </Slider>
          </div>
          <div className="arrow">
            <div
              className="--arrow prev"
              onClick={() => {
                footerImageRef.current.slickPrev();
              }}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </div>
            <div
              className="--arrow next"
              onClick={() => {
                footerImageRef.current.slickNext();
              }}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </div>
        </div>
        <img src="/static/img/cloud2.png" alt="" className="--cloud" />
      </div>
      <div className="footerCenter">
        <div className="container-fluid">
          <div className="--item">
            <div className="--name">{footer?.cities?.title}</div>
            <div className="--listLink">
              {footer?.cities?.items?.map((item, i) => (
                <LinkInOutApp
                  href={item?.link}
                  className="--itemLink link"
                  key={i}
                >
                  {item?.text}
                </LinkInOutApp>
              ))}
            </div>
          </div>
          <div className="--item">
            <div className="--name">{footer?.attactions?.title}</div>
            <div className="--listLink">
              {footer?.attactions?.items?.map((item, i) => (
                <LinkInOutApp
                  href={item?.link}
                  className="--itemLink link"
                  key={i}
                >
                  {item?.text}
                </LinkInOutApp>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footerBot">
        <div className="--top">
          <div className="container-fluid">
            <div className="--col">
              <div className="--logo">
                <img src="/static/img/logoab.png" alt="" />
              </div>
              <div className="--title">Sun Paradise Land</div>
              {footer?.social?.items?.length && (
                <div className="--follow">
                  <span>{footer?.social?.title}</span>
                  <div className="--listSocial">
                    {footer?.social?.items?.map((item, i) => (
                      <LinkInOutApp
                        href={item?.link}
                        className="--itemSocial link"
                        key={i}
                      >
                        <img src={item?.icon?.thumb640} alt="" />
                      </LinkInOutApp>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="--col">
              <div className="--name">{footer?.about?.title}</div>
              <div className="--listMenu">
                <div className="--itemMenu link" onClick={() => handleIntro()}>
                  Giới thiệu Sun Paradise Land
                </div>
                {footer?.about?.items?.map((item, i) => (
                  <LinkInOutApp
                    href={item?.link}
                    className="--itemMenu link"
                    key={i}
                  >
                    {item?.text}
                    {item?.icon?.thumb640 && (
                      <img src={item?.icon?.thumb640} alt="" />
                    )}
                  </LinkInOutApp>
                ))}
              </div>
            </div>
            <div className="--col">
              <div className="--name">{footer?.destination?.title}</div>
              <div className="--listMenu">
                {footer?.destination?.items?.map((item, i) => (
                  <LinkInOutApp
                    href={item?.link}
                    className="--itemMenu link"
                    key={i}
                  >
                    {item?.text}
                  </LinkInOutApp>
                ))}
              </div>
            </div>
            <div className="--col">
              <div className="--name">{footer?.partners?.title}</div>
              <div className="--listMenu">
                {footer?.partners?.items?.map((item, i) => (
                  <LinkInOutApp
                    href={item?.link}
                    className="--itemMenu link"
                    key={i}
                  >
                    {item?.text}
                  </LinkInOutApp>
                ))}
              </div>
            </div>
            <div className="--col">
              <div className="--name">{footer?.paradiseLand?.title}</div>
              <div className="--listMenu">
                {footer?.paradiseLand?.items?.map((item, i) => (
                  <LinkInOutApp
                    href={item?.link}
                    className="--itemMenu link"
                    key={i}
                  >
                    {item?.text}
                  </LinkInOutApp>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="--bottom">
          <div className="container-fluid">
            <div className="--copy">
              @ 2024 Sun Paradise Land. All Rights Reserved
            </div>
            <div className="--privacy">
              {footer?.policy?.items?.map((item, i) => (
                <LinkInOutApp href={item?.link} className="--item link" key={i}>
                  {item?.text}
                </LinkInOutApp>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;

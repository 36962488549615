import { take, fork, select, put, call, takeLatest } from 'redux-saga/effects';
import { isServer } from '@utils/helper';
import { BaseXHR } from '@utils/axios';
import {
  GET_AWARD,
  GET_FOOTER,
  GET_HOME,
  GET_LISTSPL,
  GET_NEWS,
  GET_TRANSLATE,
} from './slice';

export function* watchAndLog() {
  while (true) {
    try {
      const styleConsole =
        'background: yellow; font-weight: bold; color: #40a9ff8c;';
      const action = yield take('*');
      const state = yield select();
      if (action.type !== '@@router/LOCATION_CHANGE') {
        console.group(action.type);
        console.log('%cBefore State', styleConsole, state);
        console.info('%cDispatching', styleConsole, action);
        console.log('%cNext State', styleConsole, state);
        console.groupEnd();
      }
    } catch {}
  }
}

export function* getHome() {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      '/api/home?SiteCode=SunParadiseLand',
      undefined,
    );
    yield put(GET_HOME.success(data));
  } catch (err) {
    yield put(GET_HOME.failure());
  }
}
export function* getTranslate() {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      '/api/language/translator/web',
      undefined,
    );
    yield put(GET_TRANSLATE.success(data));
  } catch (err) {
    yield put(GET_TRANSLATE.failure());
  }
}
export function* getAward() {
  try {
    const { data } = yield call(BaseXHR.$get, '/api/award/listing', undefined);
    yield put(GET_AWARD.success(data.data));
  } catch (err) {
    yield put(GET_AWARD.failure());
  }
}
export function* getNews({ payload }: any) {
  try {
    const params = new URLSearchParams();
    const { park, hotNews, categories, page, limit, category } = payload;
    params.append('SiteCode', park || 'SunParadiseLand');
    if (categories) {
      params.append('category', String(categories));
    }
    if (hotNews) {
      params.append('hotNews', String(hotNews));
    }
    if (page && limit) {
      params.append('page', String(page));
      params.append('limit', String(limit));
    }
    if (category) {
      params.append('category', String(category));
    }
    const { data } = yield call(
      BaseXHR.$get,
      `/api/news/listing?${params.toString()}`,
      undefined,
    );

    yield put(GET_NEWS.success(data));
  } catch (err) {
    yield put(GET_NEWS.failure());
  }
}

export function* getListSPL() {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      '/api/sunparadiseland/listing',
      undefined,
    );
    yield put(GET_LISTSPL.success(data));
  } catch (err) {
    yield put(GET_LISTSPL.failure());
  }
}
export function* getFooter() {
  try {
    const { data: about } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/about_us',
      undefined,
    );
    const { data: paradiseLand } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/paradise_land',
      undefined,
    );
    const { data: destination } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/top_destinations',
      undefined,
    );
    const { data: partners } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/partners',
      undefined,
    );
    const { data: attactions } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/popular_attactions',
      undefined,
    );
    const { data: cities } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/popular_cities',
      undefined,
    );
    const { data: social } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/footer_social',
      undefined,
    );
    const { data: gift } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/phieu_qua_tang',
      undefined,
    );
    const { data: policy } = yield call(
      BaseXHR.$get,
      '/api/menu/detail-by-key/policy',
      undefined,
    );
    yield put(
      GET_FOOTER.success({
        about: about,
        paradiseLand: paradiseLand,
        destination: destination,
        partners: partners,
        cities: cities,
        attactions: attactions,
        social: social,
        gift: gift,
        policy: policy,
      }),
    );
  } catch (err) {
    yield put(GET_FOOTER.failure());
  }
}

const homeSagas: any = [
  takeLatest(GET_HOME.TRIGGER, getHome),
  takeLatest(GET_AWARD.TRIGGER, getAward),
  takeLatest(GET_NEWS.TRIGGER, getNews),
  takeLatest(GET_LISTSPL.TRIGGER, getListSPL),
  takeLatest(GET_FOOTER.TRIGGER, getFooter),
  takeLatest(GET_TRANSLATE.TRIGGER, getTranslate),
];

if (!isServer && process.env.NODE_ENV === 'development')
  homeSagas.push(fork(watchAndLog));

export default homeSagas;
